'use client'

import { FC, useCallback, useEffect, useState } from 'react'
import {
  ActionMeta,
  GroupBase,
  MultiValue,
  OptionsOrGroups,
  SingleValue
} from 'react-select'
import { MultipleSelect } from '~/core/ui/MultipleSelect'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps,
  SelectSizeProps,
  SelectProps
} from '~/core/ui/Select'
import { debounce } from '~/core/ui/utils'

interface AsyncMultipleSearchWithSelectProps {
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  value?: ISelectOption | Array<ISelectOption>
  onChange: (
    newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
    actionMeta: ActionMeta<ISelectOption>
  ) => void
  size?: SelectSizeProps
  placeholder?: string
  isMultiShowType?: 'default' | 'ava-leading' | 'dot-leading' | 'icon-leading'
  isMultiComponent?: 'InputChips' | 'SuggestionChipsItem'
  configSelectOption?: SelectOptionProps
  destructive?: boolean
  className?: string
  classNameOverride?: SelectClassControl
  menuPlacement?: 'top' | 'auto' | 'bottom'
  // for outline search which the menu alway open
  loadAsyncWhenRender?: boolean
  loadAsyncWhenOpen?: boolean
  detectAvatarFromOption?: boolean
  creatable?: boolean
  isDropdown?: boolean
  showDropdownIndicator?: boolean
  cacheOptions?: string
  isSearchable?: boolean
  isDisabled?: boolean
  extraItem?: ISelectOption
  onInputChange?: SelectProps['onInputChange']
  isValidNewOption?: SelectProps['isValidNewOption']
  callbackClearSearchData?: () => void
  isClearable?: boolean
  mappingGroupData?: (
    data?: ISelectOption[]
  ) => OptionsOrGroups<ISelectOption, GroupBase<ISelectOption>>
  limit?: number
}

const AsyncMultipleSearchWithSelect: FC<AsyncMultipleSearchWithSelectProps> = ({
  promiseOptions,
  value,
  onChange,
  placeholder = 'Select',
  size = 'md',
  loadAsyncWhenRender,
  loadAsyncWhenOpen = true,
  detectAvatarFromOption,
  creatable = false,
  cacheOptions = '',
  isSearchable = true,
  extraItem,
  isDisabled = false,
  mappingGroupData,
  callbackClearSearchData,
  limit = 25,
  ...props
}) => {
  const [isSearch, setSearch] = useState(false)
  const [searchInput, setSearchInput] = useState<string | undefined>()
  const [options, setOptions] = useState([])
  const [optionsForView, setOptionsForView] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [pageState, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>(25)

  const handleFetch = async ({
    page = 1,
    mergedData = false
  }: {
    page: number
    mergedData: boolean
  }) => {
    if (page !== pageState) {
      setPage(page)
    }
    if (promiseOptions && totalCount > options.length) {
      setLoading(true)
      const response = await promiseOptions({
        search: searchInput || '',
        limit,
        page
      })
      setLoading(false)
      setTotalCount(response.metadata?.totalCount || 0)
      if (!mappingGroupData) {
        const cloneData = [...options, ...response.collection]
        const optionsMerge = handle4ExtraItem(
          mergedData ? cloneData : response.collection
        )
        setOptions(optionsMerge as any)
        setOptionsForView(optionsMerge as any)
      } else {
        const groupingResponseData = mappingGroupData(
          handle4ExtraItem(response.collection)
        )

        const mergeGrouping = groupingResponseData.reduce(
          (result, resGroup) => {
            const indexGroupLabel = options.findIndex(
              (groupOption: { label: string }) =>
                groupOption?.label === resGroup?.label
            )

            const mergeOptions =
              indexGroupLabel >= 0
                ? {
                    label: (options as any)[indexGroupLabel]?.label,
                    options: [
                      ...(options as any)[indexGroupLabel]?.options,
                      ...(resGroup as GroupBase<ISelectOption>).options
                    ]
                  }
                : resGroup
            return [...result, mergeOptions]
          },
          [] as any
        )
        setOptions(mergeGrouping as any)
        setOptionsForView(mergeGrouping as any)
      }
    }
  }
  const clearSearchData = useCallback(() => {
    setPage(1)
    setTotalCount(25)
    setOptions([])
    setOptionsForView([])
    callbackClearSearchData && callbackClearSearchData()
  }, [])

  const handlePromiseOptions = async (
    search: string,
    callback: (options: never[]) => void
  ) => {
    if (promiseOptions) {
      setSearch(true)
      setSearchInput(search || '')
      setLoading(true)
      const response = await promiseOptions({
        search,
        limit,
        page: 1
      })
      setLoading(false)
      setPage(1)
      setTotalCount(response.metadata?.totalCount || 0)
      setOptions(response.collection)
      callback(response.collection)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsDebounced = useCallback(
    debounce(async (search: string, callback: (options: never[]) => void) => {
      handlePromiseOptions(search, callback)
    }, 500),
    [promiseOptions]
  )

  const handle4ExtraItem = (listOptions: never[]) => {
    if (extraItem) {
      let findIndexItem = listOptions.findIndex(
        (option: { value: string }) => option.value === extraItem.value
      )
      let cloneData = [...listOptions]
      if (findIndexItem !== -1) {
        cloneData[findIndexItem] = extraItem as never
      } else {
        cloneData = [extraItem as never, ...listOptions]
      }
      return cloneData
    }
    return listOptions
  }

  useEffect(() => {
    if (loadAsyncWhenRender) {
      handleFetch({ page: 1, mergedData: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <MultipleSelect
      isDisabled={isDisabled}
      async
      cacheOptions={false}
      isLoading={isLoading}
      defaultOptions={isSearch ? options : optionsForView}
      isSearchable={isSearchable}
      loadOptions={loadOptionsDebounced}
      onMenuOpen={() => {
        if (loadAsyncWhenOpen) {
          handleFetch({ page: pageState, mergedData: false })
        }
      }}
      onMenuClose={() => {
        setSearch(false)
        setSearchInput(undefined)
        if (!props.isDropdown) {
          clearSearchData()
        }
      }}
      onMenuScrollToBottom={() => {
        if (isSearch) {
          if (totalCount > options.length) {
            handleFetch({ page: pageState + 1, mergedData: true })
          }
        } else {
          handleFetch({ page: pageState + 1, mergedData: true })
        }
      }}
      size={size}
      creatable={creatable}
      placeholder={placeholder}
      onChange={onChange}
      value={value || []}
      {...props}
      onInputChange={(input, actionMeta) => {
        if (input === '' && actionMeta.action === 'input-change') {
          setTimeout(() => {
            handlePromiseOptions(input, () => {})
          }, 500)
        }
        if (actionMeta.action === 'input-change' && props.onInputChange) {
          props.onInputChange(input, actionMeta)
        }
      }}
      isValidNewOption={props?.isValidNewOption}
      configSelectOption={{
        ...props.configSelectOption,
        ...(detectAvatarFromOption
          ? {
              avatar: options.find((item) =>
                Object.keys(item).includes('avatar')
              )
            }
          : {})
      }}
    />
  )
}

AsyncMultipleSearchWithSelect.displayName = 'AsyncMultipleSearchWithSelect'

export { AsyncMultipleSearchWithSelect }
export type { AsyncMultipleSearchWithSelectProps }
