'use client'

import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ActionMeta, MultiValue, SingleValue } from 'react-select'
import { NativeSelect } from '~/core/ui/NativeSelect'
import {
  IPromiseSearchOption,
  ISelectOption,
  SelectClassControl,
  SelectOptionProps,
  SelectSizeProps,
  SelectProps,
  SelectVariantProps
} from '~/core/ui/Select'
import { debounce } from '~/core/ui/utils'

interface AsyncSingleSearchWithSelectProps {
  promiseOptions?: (
    params: IPromiseSearchOption
  ) => Promise<{ metadata?: { totalCount: number }; collection: never[] }>
  value?: ISelectOption | Array<ISelectOption>
  onChange: (
    newValue: SingleValue<ISelectOption> | MultiValue<ISelectOption> | null,
    actionMeta: ActionMeta<ISelectOption>
  ) => void
  size?: SelectSizeProps
  placeholder?: string
  configSelectOption?: SelectOptionProps
  destructive?: boolean
  className?: string
  classNameOverride?: SelectClassControl
  loadAsyncWhenRender?: boolean
  loadAsyncWhenOpen?: boolean
  menuPlacement?: 'top' | 'auto' | 'bottom'
  creatable?: boolean
  isDropdown?: boolean
  variant?: SelectVariantProps
  cacheOptions?: string
  extraItem?: ISelectOption
  isSearchable?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  menuIsOpen?: boolean
  onInputChange?: SelectProps['onInputChange']
  isValidNewOption?: SelectProps['isValidNewOption']
  callbackClearSearchData?: () => void
  showGhostDropdownIndicator?: boolean
  limit?: number
}

const AsyncSingleSearchWithSelect: FC<AsyncSingleSearchWithSelectProps> = ({
  promiseOptions = undefined,
  value,
  onChange,
  placeholder = 'Select',
  size = 'md',
  loadAsyncWhenRender,
  loadAsyncWhenOpen = true,
  creatable = false,
  cacheOptions = '',
  extraItem,
  isSearchable = true,
  isClearable = true,
  callbackClearSearchData,
  limit = 25,
  ...props
}) => {
  const [isSearch, setSearch] = useState(false)
  const [searchInput, setSearchInput] = useState<string | undefined>()
  const [optionsForView, setOptionsForView] = useState([])
  const [options, setOptions] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [pageState, setPage] = useState(1)
  const [totalCount, setTotalCount] = useState<number>(25)

  const handleFetch = async ({
    page = 1,
    mergedData = false
  }: {
    page: number
    mergedData: boolean
  }) => {
    if (page !== pageState) {
      setPage(page)
    }
    if (promiseOptions && totalCount > options.length) {
      setLoading(true)
      const response = (await promiseOptions({
        search: searchInput || '',
        limit,
        page
      })) || {
        collection: []
      }
      
      setLoading(false)
      setTotalCount(response.metadata?.totalCount || 0)
      const cloneData = [...options, ...response.collection]
      setOptions(handle4ExtraItem(mergedData ? cloneData : response.collection))
      setOptionsForView(
        handle4ExtraItem(mergedData ? cloneData : response.collection)
      )
    }
  }

  const clearSearchData = () => {
    setPage(1)
    setTotalCount(25)
    setOptions([])
    setOptionsForView([])
    callbackClearSearchData && callbackClearSearchData()
  }

  const handlePromiseOptions = async (
    search: string,
    callback: (options: never[]) => void
  ) => {
    if (promiseOptions) {
      setSearch(true)
      setSearchInput(search || '')
      setLoading(true)
      const response = await promiseOptions({
        search,
        limit,
        page: 1
      })
      

      setLoading(false)
      setPage(1)
      setTotalCount(response?.metadata?.totalCount || 0)
      setOptions(response.collection)
      callback(response.collection)
    }
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const loadOptionsDebounced = useCallback(
    debounce(async (search: string, callback: (options: never[]) => void) => {
      handlePromiseOptions(search, callback)
    }, 500),
    [handlePromiseOptions, promiseOptions]
  )
  useEffect(() => {
    if (loadAsyncWhenRender) {
      handleFetch({ page: 1, mergedData: false })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handle4ExtraItem = (listOptions: never[]) => {
    if (extraItem) {
      let findIndexItem = listOptions.findIndex(
        (option: { value: string }) => option.value === extraItem.value
      )
      let cloneData = [...listOptions]
      if (findIndexItem !== -1) {
        cloneData[findIndexItem] = extraItem as never
      } else {
        cloneData = [extraItem as never, ...listOptions]
      }
      return cloneData
    }
    return listOptions
  }
  return (
    <NativeSelect
      isClearable={isClearable}
      async={true}
      cacheOptions={cacheOptions}
      isSearchable={isSearchable}
      isLoading={isLoading}
      defaultOptions={isSearch ? options : optionsForView}
      loadOptions={loadOptionsDebounced}
      onMenuOpen={() => {
        if (loadAsyncWhenOpen) {
          handleFetch({ page: pageState, mergedData: false })
        }
      }}
      onMenuClose={() => {
        setSearch(false)
        setSearchInput(undefined)
        if (!props.isDropdown) {
          clearSearchData()
        }
      }}
      onMenuScrollToBottom={() => {
        if (isSearch) {
          if (totalCount > options.length) {
            handleFetch({ page: pageState + 1, mergedData: true })
          }
        } else {
          handleFetch({ page: pageState + 1, mergedData: true })
        }
      }}
      size={size}
      creatable={creatable}
      placeholder={placeholder}
      onChange={onChange}
      value={value || []}
      {...props}
      onInputChange={(input, actionMeta) => {
        if (input === '' && actionMeta.action === 'input-change') {
          setTimeout(() => {
            handlePromiseOptions(input, () => {})
          }, 500)
        }
        if (actionMeta.action === 'input-change' && props.onInputChange) {
          props.onInputChange(input, actionMeta)
        }
      }}
      isValidNewOption={props.isValidNewOption}
    />
  )
}

AsyncSingleSearchWithSelect.displayName = 'AsyncSingleSearchWithSelect'

export { AsyncSingleSearchWithSelect }
export type { AsyncSingleSearchWithSelectProps }
